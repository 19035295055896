












































































































































.courseUserDetail{
  background-color: #f5f5f6;
  .searchboxfl {
    background-color: #fff;
    margin: 0 .675rem;
    padding-top: 20px;
  }
  .pageNum{
    padding: 0 1rem;
  }
}

