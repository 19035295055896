.courseUserDetail {
  background-color: #f5f5f6;
}
.courseUserDetail .searchboxfl {
  background-color: #fff;
  margin: 0 .675rem;
  padding-top: 20px;
}
.courseUserDetail .pageNum {
  padding: 0 1rem;
}
